
import { defineComponent, computed } from 'vue'
import ReportCards from '@/components/shared/templates/reports/ReportCards.vue'
import ReportingFullEmpty from '@/components/pages/reporting/ReportingFullEmpty.vue'
import ReportingNavigation from '@/components/pages/reporting/ReportingNavigation.vue'
import ReportingTimeline from '@/components/pages/reporting/ReportingTimeline.vue'
import ReportingOverviewTextMessagesDelivery from '@/components/pages/reporting/overview/ReportingOverviewTextMessagesDelivery.vue'
import ReportingOverviewTextMessagesGeography from '@/components/pages/reporting/overview/ReportingOverviewTextMessagesGeography.vue'
import { reportsFilter } from '@/definitions/shared/filters/filtersList/data'
import { useFilters } from '@/compositions/filters'
import { useModes } from '@/compositions/modes'
import {
  reportsOverviewTextMessagesStats,
  reportsOverviewTextMessagesStatsHalfEmpty,
  tooltipsTextMessages,
  timelineTextMessagesOptions
} from '@/definitions/reporting/overview/data'
import { reportingOverviewTimelineChartData, reportingOverviewTimelineChartDataHalfEmpty } from '@/definitions/shared/charts/reporting/data'

export default defineComponent({
  components: {
    ReportCards,
    ReportingFullEmpty,
    ReportingNavigation,
    ReportingTimeline,
    ReportingOverviewTextMessagesDelivery,
    ReportingOverviewTextMessagesGeography,
  },
  setup() {
    const { isEmptyMode, isEmptyHalfMode } = useModes()
    const { filters } = useFilters(reportsFilter)

    const stats = computed(() => isEmptyHalfMode.value ? reportsOverviewTextMessagesStatsHalfEmpty : reportsOverviewTextMessagesStats)
    const timelineChart = computed(() => isEmptyHalfMode.value ? reportingOverviewTimelineChartDataHalfEmpty : reportingOverviewTimelineChartData)

    return {
      isEmptyMode,
      filters,
      tooltips: tooltipsTextMessages,
      stats,
      timelineChart,
      timelineTextMessagesOptions,
    }
  },
})
