import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_doughnut_chart = _resolveComponent("doughnut-chart")!
  const _component_report_graph = _resolveComponent("report-graph")!

  return (_openBlock(), _createBlock(_component_report_graph, {
    title: "Geography",
    tooltip: "Recipient distribution by country for the selected period.",
    data: _ctx.reportingOverviewGeographyChartData,
    icon: "language"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_doughnut_chart, {
        "justify-center": "",
        size: "188px",
        "chart-data": _ctx.reportingOverviewGeographyChartData,
        class: "doughnut-chart"
      }, null, 8, ["chart-data"])
    ]),
    _: 1
  }, 8, ["data"]))
}