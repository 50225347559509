
import { defineComponent } from 'vue'
import DoughnutChart from '@/components/shared/charts/DoughnutChart.vue'
import ReportGraph from '@/components/shared/templates/reports/ReportGraph.vue'
import { reportingOverviewGeographyChartData } from '@/definitions/shared/charts/reporting/data'

export default defineComponent({
  components: {
    DoughnutChart,
    ReportGraph,
  },
  setup() {
    return {
      reportingOverviewGeographyChartData,
    }
  },
})
